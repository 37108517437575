import React, { Fragment } from 'react'

const Sponsors = () => {
  return (
  <Fragment>
    Sponsors view
    </Fragment>

  )
}

export default Sponsors
