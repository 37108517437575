import React, { Fragment } from 'react'
import ContentBox from '../components/contentBox'
import Btn from '../components/btn'
const FAQ = () => {
  return (
  <Fragment>
      <div className="w-90 h-a h-pr-fl-ma bg-d r-h-c m-t-20px m-b-20px b-s-b-b of-auto  of-x-hidden p-20px">
    <ContentBox className="w-100 t-a-c" title={'Preguntas frecuentes:'} subtitle={'Todo lo que necesite saber, a su alcance.'} />
    <div className="w-100  h-100 h-pr-fl-ma r-h-c b-s-b-b  ">

    <div className="w-100 m-w-100 b-s-b-b h-a h-pr-fl-ma bg-d  ns-br-r-1 of-h p-20px m-b-20px">
            <ContentBox

              title={'¿Cómo funciona el proceso de diseño y desarrollo web en OrigenStudios?.'}
              subtitle={'Sandra Esquivel - Diseñadora Web/Mobile.'}
              text={'En OrigenStudios, el proceso de diseño y desarrollo web comienza con una reunión inicial con el cliente para reconocer sus necesidades y objetivos. A partir de ahí, nuestro equipo crea un plan de trabajo detallado que incluye el diseño visual, la arquitectura de la información, la programación y la implementación. Durante todo el proceso, trabajamos en estrecha colaboración con el cliente para asegurarnos de que estamos en la misma página y que su visión se está realizando.'}
            />
          </div>

          <div className="w-100 m-w-100 b-s-b-b h-a h-pr-fl-ma bg-d  ns-br-r-1 of-h p-20px m-b-20px">
            <ContentBox

              title={'¿Cómo se asegura OrigenStudios de que el diseño y desarrollo web se adapte a mis necesidades y objetivos?.'}
              subtitle={'Exequiel Fernandez - Contador Público'}
              text={'En OrigenStudios, nos tomamos el tiempo para entender las necesidades y objetivos de cada cliente antes de comenzar cualquier proyecto. Realizamos una investigación exhaustiva sobre su marca y su industria para garantizar que estamos entregando un diseño y desarrollo web que se adapte perfectamente a sus necesidades. Fomentamos el desarrollo de productos y servicios bajo el cumplimiento de parámetros de rendimiento y calidad, para estar siempre a la vanguardia en todos los campos.'}
            />
          </div>

          <div className="w-100 m-w-100 b-s-b-b h-a h-pr-fl-ma bg-d  ns-br-r-1 of-h p-20px m-b-20px">
            <ContentBox

              title={'¿Qué plazos puedo esperar para el diseño y desarrollo web de mi proyecto?.'}
              subtitle={'Lucas Medina - Gestión comercial - Banco Provincia.'}
              text={'Los plazos para el diseño y desarrollo web de cada proyecto varían según su complejidad. En OrigenStudios, trabajamos diligentemente para cumplir con los plazos acordados con nuestros clientes y nos aseguramos de mantenerlos informados sobre cualquier posible demora.'}
            />
          </div>

          <div className="w-100 m-w-100 b-s-b-b h-a h-pr-fl-ma bg-d  ns-br-r-1 of-h p-20px m-b-20px">
            <ContentBox

              title={'¿Qué costo tendría mi proyecto de diseño y desarrollo web?.'}
              subtitle={'Pablo Francisco Jesus - Dir. Concesionaria Los Rodriguez.'}
              text={'El costo de su proyecto de diseño y desarrollo web dependerá de varios factores, como la complejidad del proyecto y el tiempo requerido para completarlo. En OrigenStudios, trabajamos con nuestros clientes para ofrecer soluciones asequibles que se adapten a sus presupuestos y necesidades.'}
            />
          </div>

          <div className="w-100 m-w-100 b-s-b-b h-a h-pr-fl-ma bg-d  ns-br-r-1 of-h p-20px m-b-20px">
            <ContentBox

              title={'¿Cuántas revisiones puedo hacer antes de que se finalice el proyecto?.'}
              subtitle={'Leonor Duran - Personal Administrativo - El Corte Inglés.'}
              text={'En OrigenStudios, trabajamos en estrecha colaboración con nuestros clientes para asegurarnos de que estamos entregando un diseño y desarrollo web que cumpla con sus expectativas. Ofrecemos varias revisiones para cada proyecto y trabajamos con nuestros clientes para garantizar que están satisfechos con el resultado final.'}
            />
          </div>

          <div className="w-100 m-w-100 b-s-b-b h-a h-pr-fl-ma bg-d  ns-br-r-1 of-h p-20px m-b-20px">
            <ContentBox

              title={'¿Cómo maneja OrigenStudios la propiedad intelectual y los derechos de autor de los trabajos que realiza?.'}
              subtitle={'Washington Rodriguez - Informático - Altos de la Costa.'}
              text={'En OrigenStudios, nos tomamos muy en serio la propiedad intelectual y los derechos de autor. Garantizamos que nuestros clientes tengan los derechos completos y exclusivos sobre cualquier trabajo que realizamos para ellos. Hacemos uso total y estricto de la RGPD (Reglamentación General de Protección de Datos / REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de abril de 2016) '}
            />
          </div>

          <div className="w-100 m-w-100 b-s-b-b h-a h-pr-fl-ma bg-d  ns-br-r-1 of-h p-20px m-b-20px">
            <ContentBox

              title={'¿Qué experiencia tiene OrigenStudios en mi sector o industria?.'}
              subtitle={'Jorge Velazquez - Productor Agrícola - España'}
              text={'En OrigenStudios, hemos trabajado con clientes en una amplia gama de sectores e industrias. Nuestro equipo de profesionales altamente capacitados y experimentados está equipado para manejar cualquier proyecto, sin importar la industria, a su vez, contamos con una red de contactos profesionales en todo el globo, que le asesorarán en lo que usted necesite.'}
            />
          </div>

          <div className="w-100 m-w-100 b-s-b-b h-a h-pr-fl-ma bg-d  ns-br-r-1 of-h p-20px m-b-20px">
            <ContentBox

              title={'¿Ofrecen servicios de mantenimiento, por ejemplo para mi web y soporte después de la finalización del proyecto?.'}
              subtitle={'Jonathan Di Amparo - Empresario Biotecnológico y Robótica.'}
              text={'Sí, ofrecemos servicios de mantenimiento y soporte después de la finalización del proyecto. Ofrecemos diferentes paquetes de soporte para garantizar que su sitio web siga funcionando sin problemas y esté actualizado.'}
            />
          </div>

          <div className="w-100 m-w-100 b-s-b-b h-a h-pr-fl-ma bg-d  ns-br-r-1 of-h p-20px m-b-20px ">
            <ContentBox
            className="w-100 t-a-c"

              title={'¿SE HA QUEDADO CON DUDAS?'}
              subtitle={'¡Comuniquese con nosotros!'}

            />
            <div className="w-100 h-a h-pr-fl-ma  m-t-10px flexbox">
              <Btn className=" p-l-20px p-r-20px " text="hello@OrigenStudios.com"></Btn>
            </div>
          </div>

          </div>
   </div>
   <div className="w-100 h-200px h-pr-fl-ma b-s-b-b  m-d-b">
            &nbsp;
          </div>
    </Fragment>

  )
}

export default FAQ
